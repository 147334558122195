import { render, staticRenderFns } from "./gift-pack-code.vue?vue&type=template&id=c678c11a&scoped=true"
import script from "./gift-pack-code.vue?vue&type=script&lang=ts"
export * from "./gift-pack-code.vue?vue&type=script&lang=ts"
import style0 from "./gift-pack-code.vue?vue&type=style&index=0&id=c678c11a&prod&lang=stylus&module=true"
import style1 from "./gift-pack-code.vue?vue&type=style&index=1&id=c678c11a&prod&lang=stylus&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "c678c11a",
  null
  
)

export default component.exports