
import { Component, Vue, Watch } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import {
  getGiftPackCodeList,
  initGiftPackCodeData,
  GiftPackCodeItem,
  createGiftPackCode,
  exportData,
} from '@/api/marketing/coupon';
import { PaginationData } from '@/api/operation/customer';
const STATUS = [
  {
    value: 2,
    label: '全部',
  },
  {
    value: 0,
    label: '未领取',
  },
  {
    value: 1,
    label: '已领取',
  },
];
@Component
export default class GiftPackCode extends Vue {
  is_loading: boolean = false;
  is_show_create_modal: boolean = false;
  created_number: number = 10;
  package_name: any = '';
  form_value: any = {
    code: '',
    status: 2,
  };
  validateMap: any = {
    numbers: {
      status: '',
      help: '',
      tip_text: '请输入正整数',
    },
  }
  gift_pack_code_data: PaginationData<GiftPackCodeItem> = initGiftPackCodeData();
  get getReceiveStatus() {
    return STATUS;
  }

  isSub: boolean=true
  //@Watch('created_number')
  changeCreatedNumber() {
    const reg = /^[1-9]\d*$/;
    if(!reg.test(String(this.created_number))){
      this.validateMap.numbers.status= 'error'
      this.validateMap.numbers.help= '请输入正整数'
      this.isSub=false
    }else{
        this.validateMap.numbers.help = ''
        this.validateMap.numbers.status = '';
        this.isSub=true
      }
    //this.created_number = this.created_number < 1 ? 1 : this.created_number;
  }

  paginationOption(data?: any) {
    return {
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      total: data.total || 0,
      pageSize: data.limit || 1,
      current: +data.current || 1,
    };
  }

//导出
  exportData() {
    console.log(this.gift_pack_code_data.detail)
    if(this.gift_pack_code_data.detail.length<=0){
      this.$message.error('暂无数据，导出失败')
    }else{
      exportData({
      get_status: this.form_value.status,
      code: this.form_value.code,
      package_id: this.$route.query.id,
    });
    }
    
  }

  // 切换分页
  @changeLoading(['is_loading'])
  async handleChange(data: any) {
    await this.getGiftPackCodeList({
      current: data.current,
      page: 50,
      get_status: this.form_value.status,
      code: this.form_value.code,
      package_id: this.$route.query.id,
    });
  }

  // 关闭模态窗口
  closeModal() {
    this.is_show_create_modal = false;
    this.created_number = 10;
  }
  // 确认生成券码
  @changeLoading(['is_loading'])
  async createCode() {
    if (this.created_number <= 0) {
      //this.$message.error('请输入生成数量');
      return;
    }
    const res = await createGiftPackCode(+this.$route.query.id, +this.created_number);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    await this.getGiftPackCodeList({
      package_id: this.$route.query.id,
      page: 50,
      current: 1,
    });
    this.$message.success('新增成功');
    this.closeModal();
  }
  // 清空搜索条件
  clearForm() {
    this.form_value = {
      code: '',
      status: 2,
    };
  }
  // 点击搜索按钮
  @changeLoading(['is_loading'])
  async clickSeachBtn() {
    const res={
      package_id: this.$route.query.id,
      page: 50,
      get_status: this.form_value.status,
      code: this.form_value.code,
      current: 1,
    }
    if(this.form_value.status==2){
      delete res.get_status
    }
    await this.getGiftPackCodeList(res);
  }
  // 获取优惠券礼包券码
  @changeLoading(['is_loading'])
  async getGiftPackCodeList(query_params?: any) {
    const res = await getGiftPackCodeList(query_params);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.gift_pack_code_data = initGiftPackCodeData(res.data);
  }
  @changeLoading(['is_loading'])
  async created() {
    this.package_name = this.$route.query.package_name;
    await this.getGiftPackCodeList({
      package_id: this.$route.query.id,
      current: 1,
      page: 50,
    });
  }
}
