import { request as axios } from '@/util/request';
import { PaginationData } from '@/api/operation/customer';
const { VUE_APP_API: FCAPI } = process.env;
const getUrl = require('baimahu/url-with-param');
const API = {
  coupon_list: '/boss/marketing/coupon', // 新增或者获取
  edit_status: '/boss/marketing/coupon/status/',
  ware_list: '/boss/product/spu', // 获取商品列表
  edit_coupon: '/boss/marketing/coupon/', // 编辑优惠券
  record: '/boss/marketing/coupon/record', // 已领取或者已使用列表
  coupon_code: '/boss/marketing/coupon/code', // 优惠券券码列表
  coupon_gift_pack: '/boss/marketing/package', // 优惠券礼包
  add_coupon_package: '/boss/marketing/package', // 新增优惠券礼包
  edit_coupon_package: '/boss/marketing/package/', // 新增优惠券礼包
  reveiced_number: '/boss/marketing/package/user', // 查询已领取数据
  gift_pack_code: '/boss/marketing/package/code', // 优惠券礼包券码列表||生成礼包券码
  export_data: `${FCAPI}/boss/marketing/package/code/export`, // 后悔券礼包 - 券码导出
  include: '/boss/marketing/coupon', // 优惠券礼包 - 包含数据查询
  export_coupon_code_data: `${FCAPI}/boss/marketing/coupon/code/export`, // 导出优惠券券码数据
  edit_gift_pack: '/boss/marketing/package/', // 编辑或者获取优惠券礼包
  package_status: `${FCAPI}/boss/marketing/package/status/`,
  marketing_user: `${FCAPI}/boss/marketing/package/user/export`, //已领取人员导出
};

// 获取优惠券列表
interface QueryParams {
  name?: string;
  type?: number;
  status?: number;
  current?: number;
  limit?: number;
}
export interface CouponData {
  issued: number;
  concessions_limit: number;
  concessions_limit_money: number;
  concessions_overlay: number;
  create_time: number;
  description: string;
  discount: number;
  down_time: number;
  end_time: number;
  expire_days: number;
  expire_type: number;
  id: number;
  money: number;
  name: string;
  personal_limit: number;
  personal_limit_number: number;
  range: number;
  received_number: number;
  shown_place: any;
  start_time: number;
  status: number;
  stock: number;
  sub_range: number;
  threshold: number;
  threshold_money: number;
  type: number;
  up_time: number;
  update_time: number;
  used_number: number;
}
export function initCouponData(remote?: PaginationData<CouponData>): PaginationData<CouponData> {
  remote = remote || ({} as PaginationData<CouponData>);
  return {
    current: +remote.current || 0,
    detail: remote.detail || [],
    limit: remote.limit || 0,
    total: remote.total || 0,
    last: remote.last || 0,
  };
}
export async function getInvoiceData(
  query_params: QueryParams = {
    limit: 20,
    current: 1,
  }
) {
  const url = getUrl(API.coupon_list, query_params);
  return await axios.get(url);
}

// 上下架优惠券
export async function editStatus(id: number, status: number) {
  return axios.put(`${API.edit_status}${id}`, {
    status,
  });
}

// 新增优惠券
interface SendData {
  type: number;
  name: string;
  threshold?: number;
  threshold_money?: number | string;
  money?: number;
  limit_money?: number;
  discount?: number | string;
  limit_discount?: number;
  concessions_limit?: number;
  concessions_limit_money?: number;
  range?: number;
  sub_range?: number;
  product_id?: [];
  category_id?: [];
  expire_type?: number;
  start_time?: number;
  end_time?: number;
  expire_days?: number | string;
  description?: number;
  stock?: number;
  personal_limit?: number;
  personal_limit_number?: number;
  concessions_overlay?: number;
  shown_place?: number;
}
export async function addCoupon(send_data: SendData) {
  return await axios.post(API.coupon_list, {
    ...send_data,
  });
}

// 编辑优惠券礼包
export async function editCouponGiftPack(send_data: any) {
  return await axios.put(`${API.edit_gift_pack}${send_data.id}`, {
    name: send_data.name,
    coupon_id_str: send_data.coupon_id_str,
    status: send_data.status,
  });
}

// 获取优惠券礼包详情
export async function getCouponGiftPackDetail(id: number) {
  return await axios.get(`${API.edit_gift_pack}${id}`);
}

// 获取优惠券礼包上下架
export async function editPackageStatus(id: number, send_data: any) {
  return await axios.put(`${API.package_status}${id}`, { status: send_data.status });
}

// 获取商品列表
export async function getWareList(
  query_params: any = {
    current: 1,
    limit: 5,
  }
) {
  const url = getUrl(API.ware_list, query_params);
  return await axios.get(url);
}

// 优惠券详情
export async function getCouponDetail(id: number) {
  return await axios.get(`${API.coupon_list}/${id}`);
}

// 编辑优惠券
export async function editCoupon(id: number, data: any) {
  return await axios.put(`${API.edit_coupon}${id}`, data);
}

// 获取已领取和已使用列表
export async function getRecordList(query_params: any) {
  const url = getUrl(API.record, query_params);
  return await axios.get(url);
}

// 获取优惠券券码
export function initCouponCodeData(
  remote?: PaginationData<GiftPackCodeItem>
): PaginationData<GiftPackCodeItem> {
  remote = (remote || {}) as PaginationData<GiftPackCodeItem>;
  return {
    current: remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  };
}
export async function getCouponCodeList(query_params: any) {
  const url = getUrl(API.coupon_code, query_params);
  return await axios.get(url);
}

// 生成券码
export async function createCouponCode(coupon_id: number, number: number) {
  return await axios.post(API.coupon_code, {
    coupon_id,
    number,
  });
}

// 导出优惠券券码数据
export function exportCouponCodeData(
  code: string,
  get_status: number,
  use_status: number,
  coupon_id: number
) {
  const url = getUrl(API.export_coupon_code_data, {
    code,
    get_status,
    use_status,
    coupon_id,
    page: 50,
  });
  //console.log(url)
  window.location.href = url;
}

// 获取优惠券礼包列表
interface GiftPackQueryParams {
  name: string;
  status: number;
  current?: number;
  page?: number;
}
export interface GiftPackItem {
  coupon_id_str: string;
  create_time: number;
  get_number: number;
  id: number;
  include_number: number;
  name: string;
  number: number;
  status: number;
  update_time: number;
}
export function initGiftPackList(
  remote?: PaginationData<GiftPackItem>
): PaginationData<GiftPackItem> {
  remote = remote || ({} as PaginationData<GiftPackItem>);
  return {
    current: remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  };
}
export async function getCouponGiftPack(query_params?: GiftPackQueryParams) {
  const url = getUrl(API.coupon_gift_pack, query_params);
  return await axios.get(url);
}

// 新增优惠券礼包
interface AddCouponModal {
  name: string;
  coupon_id_str: string;
}
export async function addCouponPackage(send_data: AddCouponModal) {
  return await axios.post(API.add_coupon_package, send_data);
}
export async function editCouponPackage(id: number, send_data: AddCouponModal) {
  return await axios.put(`${API.edit_coupon_package}${id}`, send_data);
}

// 查询已领取人员
export async function reveicedNumber(id: number) {
  const url = getUrl(API.reveiced_number, {
    package_id: id,
    page: 5,
  });
  return await axios.get(url);
}

// 获取优惠券礼包券码礼包列表
interface GiftPackCodeQueryParams {
  code?: string;
  get_status?: number;
  current?: number;
  page?: number;
}
export interface GiftPackCodeItem {
  code_str: string;
  create_time: number;
  get_status: number;
  get_time: number;
  id: number;
  package_id: number;
  update_time: number;
  use_status: number;
  use_time: number;
  user: any;
  user_id: number;
}
export function initGiftPackCodeData(remote?: PaginationData<GiftPackCodeItem>) {
  remote = (remote || {}) as PaginationData<GiftPackCodeItem>;
  return {
    current: remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  };
}
export async function getGiftPackCodeList(query_params?: GiftPackCodeQueryParams) {
  const url = getUrl(API.gift_pack_code, query_params);
  return await axios.get(url);
}

// 生成优惠券礼包券码
export async function createGiftPackCode(id: number, count: number) {
  return await axios.post(API.gift_pack_code, {
    package_id: id,
    number: count,
  });
}

// 导出优惠券礼包券码
export function exportData(query_params: any) {
  const url = getUrl(API.export_data, query_params);
  console.log(url);

  window.location.href = url;
}
//导出优惠券礼包已领取
export function exportDataing(query_params: any) {
  const url = getUrl(API.marketing_user, query_params);
  console.log(url);

  window.location.href = url;
}
// 优惠券id查询
export async function getIncludeData(
  coupon_id_str: string,
  current: number = 1,
  limit: number = 5
) {
  const url = getUrl(API.include, {
    id_str: coupon_id_str,
    current,
    limit,
  });
  return await axios.get(url);
}
